<template>

  <div>

    <div v-if="savedMortgagee && locked">
      <h3 style="display: inline-block;" class="pt-2 text-bold">{{savedMortgagee.MortgageeName}} {{formatSuffix($store.getters.getControlValue(module, suffixControl))}}</h3>
      <v-btn icon small color="secondary" @click="unlock()" class="ml-2" style="position: relative; top: -2px;" title="Edit Mortgagee" v-show="!disabled">
          <i class="fas fa-pen" style="font-size: 18px;"></i>
      </v-btn>
      <h3 class="mb-2">
        {{savedMortgagee.MortgageeAddress1}}<br>
        {{savedMortgagee.MortgageeCity}}, {{savedMortgagee.MortgageeState}} {{savedMortgagee.MortgageeZip}} {{savedMortgagee.MortgageeCountry}}
      </h3>
      <p class="mb-0">
        Loan Number: {{$store.getters.getControlValue(module, loanNumberControl)}}<br>
        <template v-if="invoiceControl">Invoice Upon Renewal: {{$store.getters.getControlValue(module, invoiceControl) ? 'Yes' : 'No'}}</template>
      </p>
    </div>

    <ValidationObserver tag="span" ref="mortgageeObserver" vid="Mortgagee" v-slot="{}">
    <v-card class="pt-4 pb-2 px-2" v-if="!locked">
      <v-card-text>
          <v-row>
            <v-col md="12" cols="12" class="py-0 mb-3">
              <DynamicFormControl 
                  :control="{
                      name: 'MortgageeOrg',
                      type: 'MortgageeOrgCombobox',
                      value: mortgageeOrg,
                      config: {
                          label: 'Mortgagee Organization',
                          rules: 'required|validMortgageeOrgName',
                          short: true
                      }
                  }"
                  @change="updateMortgageeOrg" 
                  @keypress="keypressMortgageeOrg"
              />
            </v-col>
            <v-col md="12" cols="12" class="py-0 mb-2" v-if="mortgageeOrg">
              <label class="mb-2 mt-2">Mortgagee Mailing Address:</label>
              <DynamicFormControl v-if="mortgageeOrg.ID"
                  style="margin-top: -3px;"
                  :control="{
                      type: 'RadioButtonGroup',
                      value: mode,
                      name: 'Mode',
                      config: {
                          options: [
                              {value: 'SEARCH', text: 'Branch Address Lookup'},
                              {value: 'ADVANCED', text: 'Enter New Address'}
                          ],
                          small: true,
                          short: true,
                          mandatory: true
                      }
                  }" 
                  @change="toggleMode" 
              />
              
              <div v-if="mode == 'SEARCH'" key="addressSearch">
                <DynamicFormControl 
                    :control="{
                        name: 'Mortgagee',
                        type: 'MortgageeAutocomplete',
                        value: mortgageeOrgBranch,
                        config: {
                            label: 'Mortgagee Location Address',
                            rules: 'required',
                            short: true,
                            MortgageeOrgID: mortgageeOrg.ID
                        }
                    }"
                    @change="updateMortgageeOrgBranch" 
                />
              </div>
              <div v-else-if="mode == 'ADVANCED'" key="addressAdvanced">
                <DynamicFormControl
                  :key="JSON.stringify(mortgageeAddress)"
                  :control="{
                    type:'Address',
                    name:'MortgageeAddress',
                    value: mortgageeAddress,
                    config: {
                      addressType:'MAILING',
                      label:null,
                      rules:'addressLocked',
                      col:12,
                      short: true,
                      //presetMode: 'ADVANCED',
                    }
                  }"
                  @change="updateMortgageeAddress"
                />
              </div>
            </v-col>
          </v-row>
        <v-row>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <DynamicFormControl
              :control="{
                name: suffixControl,
                type: 'Dropdown',
                value: selectedSuffix,
                config: {
                  rules: 'required',
                  label: 'Mortgagee Suffix',
                  clearable: false,
                  short: true,
                  default: 'NA',
                  options:[
                    {
                        'text':'N/A',
                        'value':'NA'
                    },
                    {
                        'text':'ISAOA ATIMA',
                        'value':'IA'
                    },
                    {
                        'text':'ISAOA',
                        'value':'I'
                    },
                    {
                        'text':'ATIMA',
                        'value':'A'
                    }
                  ]
                }
              }"
              @change="selectedSuffix = $event.value"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <DynamicFormControl
              :control="{
                name: suffixControl,
                type: 'TextInput',
                value: selectedLoanNumber,
                config: {
                  rules: 'required',
                  label: 'Loan Number',
                  maxlength: 32,
                  short: true
                }
              }"
              @change="selectedLoanNumber = $event.value"
            />
          </v-col>
          <v-col cols="12" class="py-0" v-if="invoiceControl">
            <DynamicFormControl
              :control="{
                name: invoiceControl,
                type: 'Checkbox',
                value: selectedInvoice,
                config: {
                  label: 'Invoice Upon Renewal',
                  short: true
                }
              }"
              @change="selectedInvoice = $event.value"
            />
          </v-col>
        </v-row>

        <div v-if="selectedMortgagee" class="preview">
          <br>
          <h3 style="display: inline-block;" class="pt-2 text-bold">{{selectedMortgagee.MortgageeName}} {{formatSuffix(selectedSuffix)}}</h3>
          <h3 class="mb-2">
            {{selectedMortgagee.MortgageeAddress1}}<br>
            {{selectedMortgagee.MortgageeCity}}, {{selectedMortgagee.MortgageeState}} {{selectedMortgagee.MortgageeZip}} {{selectedMortgagee.MortgageeCountry}}
          </h3>
          <p class="mb-0">
            Loan Number: {{selectedLoanNumber}}<br>
            <template v-if="invoiceControl">Invoice Upon Renewal: {{selectedInvoice ? 'Yes' : 'No'}}</template>
          </p>
        </div>

        <div class="text-right">
          <template>
              <br>
              <FormButton v-bind="{label: 'Cancel', color: 'secondary', type: 'button', text: true}" @click="cancel()" v-if="savedMortgagee"></FormButton>
              <v-btn color="secondary" x-large type="button" @click="validateMortgagee" class="ml-2">Save Mortgagee</v-btn>
          </template>
        </div>
        


      </v-card-text>
    </v-card>
    </ValidationObserver>  

    <div v-if="!locked" class="hasError">
        <ValidationProvider rules="mortgageeOpen" v-slot="{ errors }">
            <input v-model="locked" hidden />
            <br>
            <ErrorMessages :errors="errors"></ErrorMessages>
        </ValidationProvider>
    </div>   
     
  </div>
  



</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import FormButton from '@/components/form/FormButton.vue'

export default {
  name: "Mortgagee",
  components: {
    DynamicFormControl,
    FormButton
  },
  data() {
    return {
      locked: true,
      mode: 'SEARCH',
      mortgageeOrg: null,
      mortgageeOrgBranch: null,
      mortgageeAddress: null,

      selectedMortgagee: null,
      selectedSuffix: null,
      selectedLoanNumber: null,
      selectedInvoice: null,

      savedMortgagee: null,
      savedSuffix: null,
      savedLoanNumber: null,
      savedInvoice: null,
    }
  },
  props: {
    value: Object,
    rules: String,
    name: String,
    module: String,
    disabled: Boolean,
    suffixControl: String,
    loanNumberControl: String,
    invoiceControl: String
  },
  emits: [
    'change'
  ],
  watch: {
    value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            if (this.savedMortgagee != newVal) {
              this.savedMortgagee = newVal;
            }
            if (!newVal) {
              this.locked = false;
            }
          }
      }
  },
  computed: {
  },
  methods: {
    toggleMode(event) {
      this.mode = event.value;
      if (this.mode == 'SEARCH') {
        this.mortgageeAddress = null;
      } else if (this.mode == 'ADVANCED') {
        this.mortgageeOrgBranch = null;
      }
      this.selectedMortgagee = null;
    },
    formatSuffix(suffixCode) {
      let suffixFull = "";
      switch(suffixCode) {
        case "IA": suffixFull = "ISAOA ATIMA"; break;
        case "I": suffixFull = "ISAOA"; break;
        case "A": suffixFull = "ATIMA"; break;
        case "NA": suffixFull = ""; break;
      }
      return suffixFull;
    },
    unlock() {

      this.savedSuffix = this.$store.getters.getControlValue(this.module, this.suffixControl);
      this.savedLoanNumber = this.$store.getters.getControlValue(this.module,  this.loanNumberControl);
      if (this.invoiceControl) this.savedInvoice = this.$store.getters.getControlValue(this.module, this.invoiceControl);

      this.selectedSuffix = this.savedSuffix;
      this.selectedLoanNumber = this.savedLoanNumber;
      this.selectedInvoice = this.savedInvoice;

      this.mortgageeOrg = {
        ID: this.savedMortgagee.MortgageeOrgID,
        Name: this.savedMortgagee.MortgageeName
      }

      this.selectedMortgagee = {...this.savedMortgagee};

      if (!this.savedMortgagee.MortgageeOrgBranchID) {
        this.mode = 'ADVANCED';
        this.mortgageeAddress = {
          StreetAddress: this.savedMortgagee.MortgageeAddress1,
          City: this.savedMortgagee.MortgageeCity,
          State: this.savedMortgagee.MortgageeState,
          PostalCode: this.savedMortgagee.MortgageeZip,
          CountryCode: this.savedMortgagee.MortgageeCountry == 'USA' ? 'US' : this.savedMortgagee.MortgageeCountry,
          Locked: true
        };
      } 
      else {
        this.mode = 'SEARCH';
        this.mortgageeOrgBranch = {...this.savedMortgagee};
      }

      this.locked = false;

    },
    lock() {
      this.$emit('change', this.selectedMortgagee);
      this.updateValue(this.suffixControl, this.selectedSuffix);
      this.updateValue(this.loanNumberControl, this.selectedLoanNumber)
      if (this.invoiceControl) this.updateValue(this.invoiceControl, this.selectedInvoice);

      this.locked = true;
    },
    cancel() {
      this.locked = true;
    },
    validateMortgagee() {
      this.$refs.mortgageeObserver.validate()
        .then(valid => {
            if (valid) {
              this.lock();
            }
        });
    },
    keypressMortgageeOrg() {
      this.mortgageeOrgBranch = null; 
      this.mortgageeAddress = null;
      this.selectedMortgagee = null;
    },
    updateMortgageeOrg(event) {
      if (event && event.value) {
        //alert(JSON.stringify(event.value));
        this.mortgageeOrg = event.value;

        if (this.mortgageeOrg && this.mortgageeOrg.SuffixCode) {
          const suffixCode = this.mortgageeOrg.SuffixCode;
          this.setSuffixCode(suffixCode);
        }

        if (this.mortgageeOrg.ID) {
          this.mode = 'SEARCH';
        } else {
          this.mode = 'ADVANCED';
        }
        

      } else {
        this.mortgageeOrg = null;
      }

      this.mortgageeOrgBranch = null;
      this.mortgageeAddress = null;
      this.selectedMortgagee = null;
    },
    updateMortgageeOrgBranch(event) {
      this.mortgageeOrgBranch = event.value;
      this.selectedMortgagee = event.value;
    },
    updateMortgageeAddress(event) {
      if (event && event.value) {
        this.mortgageeAddress = event.value;
        const obj = event.value;
        this.selectedMortgagee = {
          MortgageeName: this.stripMortgageeName(this.mortgageeOrg.Name),
          MortgageeAddress1: obj.StreetAddress,
          MortgageeCity: obj.City,
          MortgageeState: obj.State,
          MortgageeZip: obj.PostalCode,
          MortgageeCountry: obj.CountryCode == 'US' ? 'USA' : obj.CountryCode,
          MortgageeOrgID: this.mortgageeOrg.ID,
          MortgageeOrgBranchID: null
        }
      }
    },
    stripMortgageeName(MortgageeName) {
      const suffixes = ["ISAOA ATIMA", "ISAOA", "ATIMA"];
      //const suffixCodes = ["IA", "I", "A"];
      suffixes.forEach((s) => {
        if (MortgageeName.indexOf(s) > -1) {
          this.setSuffixCode(s);
          MortgageeName = MortgageeName.split(s).join("");
        }
      });
      return MortgageeName;
    },
    setSuffixCode(suffixCode) {
      if (!suffixCode) return;
      this.selectedSuffix = suffixCode;
    },
    updateValue(name, value) {
        this.$store.commit('updateFormControl', {
            module: this.module,
            name,
            value
        });
    },
    openFive9() {
      this.$store.dispatch('openFive9');
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

  ::v-deep .v-radio label {color: $gray1;}

  label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: $gray1;
    }

  .preview, p {
    color: #333333;
  }

</style>