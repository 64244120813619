var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.savedMortgagee && _vm.locked)?_c('div',[_c('h3',{staticClass:"pt-2 text-bold",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.savedMortgagee.MortgageeName)+" "+_vm._s(_vm.formatSuffix(_vm.$store.getters.getControlValue(_vm.module, _vm.suffixControl))))]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],staticClass:"ml-2",staticStyle:{"position":"relative","top":"-2px"},attrs:{"icon":"","small":"","color":"secondary","title":"Edit Mortgagee"},on:{"click":function($event){return _vm.unlock()}}},[_c('i',{staticClass:"fas fa-pen",staticStyle:{"font-size":"18px"}})]),_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.savedMortgagee.MortgageeAddress1)),_c('br'),_vm._v(" "+_vm._s(_vm.savedMortgagee.MortgageeCity)+", "+_vm._s(_vm.savedMortgagee.MortgageeState)+" "+_vm._s(_vm.savedMortgagee.MortgageeZip)+" "+_vm._s(_vm.savedMortgagee.MortgageeCountry)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Loan Number: "+_vm._s(_vm.$store.getters.getControlValue(_vm.module, _vm.loanNumberControl))),_c('br'),(_vm.invoiceControl)?[_vm._v("Invoice Upon Renewal: "+_vm._s(_vm.$store.getters.getControlValue(_vm.module, _vm.invoiceControl) ? 'Yes' : 'No'))]:_vm._e()],2)],1):_vm._e(),_c('ValidationObserver',{ref:"mortgageeObserver",attrs:{"tag":"span","vid":"Mortgagee"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [(!_vm.locked)?_c('v-card',{staticClass:"pt-4 pb-2 px-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0 mb-3",attrs:{"md":"12","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                    name: 'MortgageeOrg',
                    type: 'MortgageeOrgCombobox',
                    value: _vm.mortgageeOrg,
                    config: {
                        label: 'Mortgagee Organization',
                        rules: 'required|validMortgageeOrgName',
                        short: true
                    }
                }},on:{"change":_vm.updateMortgageeOrg,"keypress":_vm.keypressMortgageeOrg}})],1),(_vm.mortgageeOrg)?_c('v-col',{staticClass:"py-0 mb-2",attrs:{"md":"12","cols":"12"}},[_c('label',{staticClass:"mb-2 mt-2"},[_vm._v("Mortgagee Mailing Address:")]),(_vm.mortgageeOrg.ID)?_c('DynamicFormControl',{staticStyle:{"margin-top":"-3px"},attrs:{"control":{
                    type: 'RadioButtonGroup',
                    value: _vm.mode,
                    name: 'Mode',
                    config: {
                        options: [
                            {value: 'SEARCH', text: 'Branch Address Lookup'},
                            {value: 'ADVANCED', text: 'Enter New Address'}
                        ],
                        small: true,
                        short: true,
                        mandatory: true
                    }
                }},on:{"change":_vm.toggleMode}}):_vm._e(),(_vm.mode == 'SEARCH')?_c('div',{key:"addressSearch"},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'Mortgagee',
                      type: 'MortgageeAutocomplete',
                      value: _vm.mortgageeOrgBranch,
                      config: {
                          label: 'Mortgagee Location Address',
                          rules: 'required',
                          short: true,
                          MortgageeOrgID: _vm.mortgageeOrg.ID
                      }
                  }},on:{"change":_vm.updateMortgageeOrgBranch}})],1):(_vm.mode == 'ADVANCED')?_c('div',{key:"addressAdvanced"},[_c('DynamicFormControl',{key:JSON.stringify(_vm.mortgageeAddress),attrs:{"control":{
                  type:'Address',
                  name:'MortgageeAddress',
                  value: _vm.mortgageeAddress,
                  config: {
                    addressType:'MAILING',
                    label:null,
                    rules:'addressLocked',
                    col:12,
                    short: true,
                    //presetMode: 'ADVANCED',
                  }
                }},on:{"change":_vm.updateMortgageeAddress}})],1):_vm._e()],1):_vm._e()],1),_c('v-row'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: _vm.suffixControl,
              type: 'Dropdown',
              value: _vm.selectedSuffix,
              config: {
                rules: 'required',
                label: 'Mortgagee Suffix',
                clearable: false,
                short: true,
                default: 'NA',
                options:[
                  {
                      'text':'N/A',
                      'value':'NA'
                  },
                  {
                      'text':'ISAOA ATIMA',
                      'value':'IA'
                  },
                  {
                      'text':'ISAOA',
                      'value':'I'
                  },
                  {
                      'text':'ATIMA',
                      'value':'A'
                  }
                ]
              }
            }},on:{"change":function($event){_vm.selectedSuffix = $event.value}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: _vm.suffixControl,
              type: 'TextInput',
              value: _vm.selectedLoanNumber,
              config: {
                rules: 'required',
                label: 'Loan Number',
                maxlength: 32,
                short: true
              }
            }},on:{"change":function($event){_vm.selectedLoanNumber = $event.value}}})],1),(_vm.invoiceControl)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
              name: _vm.invoiceControl,
              type: 'Checkbox',
              value: _vm.selectedInvoice,
              config: {
                label: 'Invoice Upon Renewal',
                short: true
              }
            }},on:{"change":function($event){_vm.selectedInvoice = $event.value}}})],1):_vm._e()],1),(_vm.selectedMortgagee)?_c('div',{staticClass:"preview"},[_c('br'),_c('h3',{staticClass:"pt-2 text-bold",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.selectedMortgagee.MortgageeName)+" "+_vm._s(_vm.formatSuffix(_vm.selectedSuffix)))]),_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.selectedMortgagee.MortgageeAddress1)),_c('br'),_vm._v(" "+_vm._s(_vm.selectedMortgagee.MortgageeCity)+", "+_vm._s(_vm.selectedMortgagee.MortgageeState)+" "+_vm._s(_vm.selectedMortgagee.MortgageeZip)+" "+_vm._s(_vm.selectedMortgagee.MortgageeCountry)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Loan Number: "+_vm._s(_vm.selectedLoanNumber)),_c('br'),(_vm.invoiceControl)?[_vm._v("Invoice Upon Renewal: "+_vm._s(_vm.selectedInvoice ? 'Yes' : 'No'))]:_vm._e()],2)]):_vm._e(),_c('div',{staticClass:"text-right"},[[_c('br'),(_vm.savedMortgagee)?_c('FormButton',_vm._b({on:{"click":function($event){return _vm.cancel()}}},'FormButton',{label: 'Cancel', color: 'secondary', type: 'button', text: true},false)):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","x-large":"","type":"button"},on:{"click":_vm.validateMortgagee}},[_vm._v("Save Mortgagee")])]],2)],1)],1):_vm._e()]}}])}),(!_vm.locked)?_c('div',{staticClass:"hasError"},[_c('ValidationProvider',{attrs:{"rules":"mortgageeOpen"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.locked),expression:"locked"}],attrs:{"hidden":""},domProps:{"value":(_vm.locked)},on:{"input":function($event){if($event.target.composing)return;_vm.locked=$event.target.value}}}),_c('br'),_c('ErrorMessages',{attrs:{"errors":errors}})]}}],null,false,2285863499)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }